//Libraries
import React, { FunctionComponent } from 'react'
import cs from 'classnames'
import { Link } from 'gatsby'

//Types
import { IconWithButtonTypes } from '../z_components-types'

//Styles
import styles from './css/iconWithTextAndButton.module.scss'

const IconWithTextAndButton: FunctionComponent<IconWithButtonTypes> = ({
  icon,
  link,
}) => (
  <div className={styles.iconWithTextAndButton}>
    <i className={cs(icon, styles.icon)} />
    {link.url.includes('https') ? (
      <a
        draggable={false}
        role="button"
        href={link.url}
        className={styles.button}
      >
        {link.name}
      </a>
    ) : (
      <Link
        draggable={false}
        role="button"
        to={link.url}
        className={styles.button}
      >
        {link.name}
      </Link>
    )}
  </div>
)

export default IconWithTextAndButton
