//Libraries
import React, { StrictMode, FunctionComponent } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet, HelmetProvider } from 'react-helmet-async'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { HomePageTypes } from './z_page-types'

//Styles
import styles from './css-pages/pages.module.scss'
import './css-pages/index.scss'
import 'remixicon/fonts/remixicon.css'
import './css-pages/bootstrap.min.scss'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import DirectionCard from '../components/free/directionCard'
import AnnouncementBar from '../components/header/announcementBar'
import IconWithTextAndButton from '../components/free/iconWithButton'
import ListSection from '../components/templates/listSection'

const Index: FunctionComponent<{}> = () => {
  //Data for announcements and cms text section on home page
  const homePageData: HomePageTypes = useStaticQuery(graphql`
    query homePageQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            template_key: { in: ["home-page", "home-content", "announcement"] }
          }
        }
      ) {
        edges {
          node {
            html
            frontmatter {
              template_key
              title
              date
              img {
                childImageSharp {
                  fluid(maxWidth: 1700, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              isImgContain
              short_description
              linkText
              link
              name
              affiliations {
                img {
                  childImageSharp {
                    fluid(maxWidth: 1700, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                link
                name
              }
            }
          }
        }
      }
    }
  `)

  //Getting different types of data
  const { allMarkdownRemark } = homePageData
  const { edges } = allMarkdownRemark

  const announcementsData = edges.filter(
    data => data.node.frontmatter.template_key === 'announcement'
  )
  const pageData = edges.filter(
    data => data.node.frontmatter.template_key === 'home-page'
  )[0]
  const contentData = edges.filter(
    data => data.node.frontmatter.template_key === 'home-content'
  )[0]

  //Data for Volunteer and Donate section on home page
  const volunteerDonateSection = {
    html: contentData.node.html,
    frontmatter: {
      name: contentData.node.frontmatter.name,
      isImgContain: contentData.node.frontmatter.isImgContain,
      img: contentData.node.frontmatter.img,
    },
  }

  return (
    <StrictMode>
      <FontSizeProvider>
        <HelmetProvider>
          <Helmet htmlAttributes={{ lang: 'en' }}>
            <meta charSet="utf-8" />
            <title>Office On Aging - College of Southern Idaho</title>
            <meta
              name="description"
              content="The CSI Office on Aging assumes the major responsibility to develop and administer a comprehensive and coordinated system of services, and to serve as the advocate and focal point to maximize the quality of life for seniors in the Magic Valley."
            />
            <link rel="canonical" href="https://ooa.csi.edu/" />
            <meta
              name="google-site-verification"
              content="-75TeJ0CWatu86OsQuFz6k4PtQLqOqBY_iTUzKUfabY"
            />
          </Helmet>
        </HelmetProvider>

        <Header />
        <Layout>
          <AnnouncementBar
            announcementsData={announcementsData}
            pageData={pageData}
          />
          <section
            className={styles.homePageIconSection}
            style={{
              margin: '0 7vw 100px 7vw',
            }}
          >
            <IconWithTextAndButton
              icon="ri-calendar-todo-fill"
              link={{
                name: 'See Events',
                url: '/events',
              }}
            />
            <IconWithTextAndButton
              icon="ri-draft-line"
              link={{
                name: 'Area Plan',
                url:
                  'https://drive.google.com/file/d/10hUeRJwdnWuxzGEMFb2sgkIAcFyZXXss/view',
              }}
            />
            <IconWithTextAndButton
              icon="ri-phone-line"
              link={{
                name: 'Contact Us',
                url: '/contact',
              }}
            />
          </section>

          <hr />

          {pageData.node.html && (
            <>
              <br />
              <section
                className={styles.homePageText}
                dangerouslySetInnerHTML={{
                  __html: pageData.node.html,
                }}
              ></section>
              <br />
              <hr />
            </>
          )}

          <ListSection data={volunteerDonateSection} orientation={'reverse'} />

          <hr />

          <section className={styles.objectFitCards}>
            <a
              id={'affiliations'}
              href="/#affiliations"
              style={{
                color: 'black',
                textDecoration: 'none',
                flexBasis: '100%',
                pointerEvents: 'none',
              }}
            >
              <h2 className="center">AFFILIATIONS</h2>
            </a>
            {contentData.node.frontmatter.affiliations.map(affiliation => (
              <DirectionCard
                key={affiliation.name}
                img={affiliation.img}
                links={[
                  {
                    name: affiliation.name,
                    link: affiliation.link,
                  },
                ]}
              />
            ))}
          </section>
        </Layout>
        <Footer />
      </FontSizeProvider>
    </StrictMode>
  )
}
export default Index
