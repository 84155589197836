//Libraries
import React, { useState, useEffect, FunctionComponent } from 'react'
import cs from 'classnames'
import Swipe from 'react-easy-swipe'

//Types
import { AnnouncementBarTypes } from '../z_components-types'

//Styles
import styles from './css/announcementBar.module.scss'

//Components
import FontResize from './fontResize'
import PageWelcome from '../header/pageWelcome'

//Static
import { compareDates } from '../../../static/reuseableFunctions'

const AnnouncementBar: FunctionComponent<AnnouncementBarTypes> = ({
  announcementsData,
  pageData,
}) => {
  //filters out placeholder
  const announcements = announcementsData.filter(
    announcement => announcement.node.frontmatter.title !== 'placeholder'
  )

  //Set total length of announcement bar
  const announcementLength = (announcements.length - 1) * 100
  const [announcementPosition, setAnnouncementPosition] = useState(0)
  const [swipePosition, setSwipePosition] = useState(0)
  const [isRunning, setIsRunning] = useState(true)

  //Sets rolling annoucement header
  useEffect(() => {
    //Moves announcement bar 100vw to the left to show next announcement and resets back to 0 when it hits the last one
    const updatePosition: ReturnType<typeof setTimeout> = setTimeout(() => {
      if (announcementLength !== announcementPosition && isRunning) {
        setAnnouncementPosition(announcementPosition + 100)
      } else if (isRunning) {
        setAnnouncementPosition(0)
      } else {
        return null
      }
    }, 10000)

    return () => {
      clearTimeout(updatePosition)
    }
  }, [announcementPosition, isRunning])

  //Moves announcement bar to the right
  const moveRight: () => void = () => {
    setAnnouncementPosition(
      announcementPosition < announcementLength ? announcementPosition + 100 : 0
    )
  }

  //Moves announcement bar to the left
  const moveLeft: () => void = () => {
    setAnnouncementPosition(
      announcementPosition > 0 ? announcementPosition - 100 : announcementLength
    )
  }

  //Moves annoucement bar on arrow click
  const handleNav: (direction: string) => void = direction => {
    if (direction === 'right') {
      moveRight()
    } else if (direction === 'left') {
      moveLeft()
    }
  }

  //Tracks swipe position
  const handleSwipe: (position: {
    x: number
    y: number
  }) => void = position => {
    setSwipePosition(position.x)
  }

  //Changes announcement shown based on which way user swiped
  const handleSwipeEnd: () => void = () => {
    if (swipePosition && swipePosition > 75) {
      moveLeft()
    } else if (swipePosition && swipePosition < -75) {
      moveRight()
    }
  }

  //Enables users to navigate by position indication circles
  const handleClick: (index: number) => void = index => {
    setAnnouncementPosition(index * 100)
  }

  const formatLink: (link: string, title: string) => string = (link, title) => {
    if (link.slice(2) === 'announcements') {
      return `/${link.slice(2)}/#${title.split(' ').join('-').toLowerCase()}`
    } else if (link.startsWith('z-')) {
      return link.slice(2)
    } else {
      return link
    }
  }

  const toggleAnnouncements: () => void = () => {
    setIsRunning(!isRunning)
  }

  return (
    <Swipe
      onSwipeMove={handleSwipe}
      innerRef={() => null}
      onSwipeEnd={handleSwipeEnd}
    >
      <div className={styles.announcementBar}>
        {announcements.length ? (
          announcements
            .sort((a, b) =>
              compareDates(
                b.node.frontmatter.date.toString(),
                a.node.frontmatter.date.toString()
              )
            )
            .map((announcement, index) => {
              const a = announcement.node.frontmatter
              const fLink = formatLink(a.link, a.title)
              return (
                <div
                  key={index}
                  className={styles.announcementSegment}
                  style={{
                    transform: `translateX(-${announcementPosition}vw)`,
                  }}
                >
                  <PageWelcome
                    img={a.img}
                    imgAltText="Announcement Image"
                    body={a['short_description']}
                    title={a.title}
                    buttonText={a.linkText}
                    buttonLink={fLink}
                    announcement={true}
                  />
                </div>
              )
            })
        ) : (
          <PageWelcome
            img={pageData.node.frontmatter.img}
            imgAltText="Home Page Banner"
            title={pageData.node.frontmatter.title}
            announcement={true}
          />
        )}

        {announcements.length > 1 && (
          <>
            <span
              onClick={() => handleNav('left')}
              className={styles.arrowContainerLeft}
            >
              <i className="ri-arrow-left-s-line" />
            </span>

            <span
              onClick={() => handleNav('right')}
              className={styles.arrowContainerRight}
            >
              <i className="ri-arrow-right-s-line" />
            </span>
          </>
        )}

        {announcements.length > 1 && (
          <div className={styles.positionIndicator}>
            {announcements.map((item, index: number) => (
              <span
                key={index}
                className={cs(
                  styles.circle,
                  index * 100 === announcementPosition && styles.filled
                )}
                onClick={() => handleClick(index)}
              ></span>
            ))}
          </div>
        )}
        {announcements.length > 1 && (
          <div className={styles.toggleContainer}>
            <i
              className={cs(
                isRunning ? 'ri-pause-circle-fill' : 'ri-play-circle-fill',
                styles.toggle
              )}
              onClick={toggleAnnouncements}
            ></i>
          </div>
        )}
        <FontResize />
      </div>
    </Swipe>
  )
}

export default AnnouncementBar
